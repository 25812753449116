import './banner.scss'

const Banner = (
  {
    style,
    title
  }
) => {
  return (
    <div style={style} className="banner">
      <h1 className="title">{title}</h1>
      <p className="content">This page is the only place in the world that has not been affected by nuclear
        radiation</p>
    </div>
  )
}

export default Banner
