import tw from '../../assets/icon/tw.png'
import dis from '../../assets/icon/dis.png'
import ins from '../../assets/icon/ins.png'
import logoWhite from '../../assets/logo-white.png'
import './index.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="left">
        <div className="link">
          <a>Roadmap</a>
          <a>Team</a>
          <a>Support</a>
        </div>
        <div className="subscribe">
          <a href="https://twitter.com/ludensno3">
            <img src={tw} alt=""/>
          </a>
          <a href="https://discord.gg/gM7sYnm8m3">
            <img src={dis} alt=""/>
          </a>
          <a href="https://instagram.com/ludensno3">
            <img src={ins}/>
          </a>
        </div>
      </div>
      <div className="right">
        <div>
          <h2 className="title">WELCOME TO LUDENS NO.3...</h2>
          <p className="tip">The apocalypse is coming, are you ready?</p>
          <div className="bottom">
            <p>Made in 2042</p>
            <img src={logoWhite} alt=""/>
          </div>
        </div>
      </div>
      <div>
      
      </div>
    </footer>
  )
}

export default Footer
