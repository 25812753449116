import { useEffect, useMemo, useState } from 'react'

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl, LAMPORTS_PER_SOL } from '@solana/web3.js'
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import Navigate from './router'
import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletWallet,
    getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";
import { useStores } from './store'
import {
  ConnectionProvider, useAnchorWallet,
  WalletProvider,
} from '@solana/wallet-adapter-react'

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { getCandyMachineState } from './utils/ludens'

function App() {
    const { appStore = {} } = useStores()
    const { network, connection, candyMachineId, setWallet } = appStore
    const endpoint = useMemo(() => clusterApiUrl(network), []);


    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSlopeWallet(),
            getSolflareWallet(),
            getSolletWallet({ network }),
            getSolletExtensionWallet({ network })
        ],
        []
    );
  return (
      <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
              <WalletDialogProvider>
                  <Navigate />
              </WalletDialogProvider>
          </WalletProvider>
      </ConnectionProvider>
  );
}

export default App;
