import './index.scss'
import Leonard from '../../assets/monsters/Leonard.png'
import Nana from '../../assets/monsters/Nana.png'
import Crocco from '../../assets/monsters/Crocco.png'
import Sneeves from '../../assets/monsters/Sneeves.png'
import Princess from '../../assets/monsters/Princess.png'
import Warren from '../../assets/monsters/Warren.png'
import download from '../../assets/icon/download.png'
const Monsters = [
    {
        name: 'Leonard',
        id: '00001',
        birthday: 'DD/MM/YYYY',
        img: Leonard
    },
    {
        name: 'NANA',
        id: '00001',
        birthday: 'DD/MM/YYYY',
        img: Nana
    },
    {
        name: 'Crocco Rocco',
        id: '00001',
        birthday: 'DD/MM/YYYY',
        img: Crocco
    },
    {
        name: 'Sneeves',
        id: '00001',
        birthday: 'DD/MM/YYYY',
        img: Sneeves
    },
    {
        name: 'Princess Stella',
        id: '00001',
        birthday: 'DD/MM/YYYY',
        img: Princess
    },
    {
        name: 'Mystery Prize',
        id: '00001',
        birthday: 'DD/MM/YYYY',
        img: Warren
    }
]
const Mine = () => {

    return (
        <div>
            <div className="mine">
                <div className="top">
                    <h1>My Ludens Niki</h1>
                </div>
                <section className="container">
                    <div className="title">
                        <div>My Ludens NIKI([10])</div>
                        <a>Download All</a>
                    </div>
                    <main className="main">
                        <ul className="list">
                            {
                                Monsters.map((item, index) =>
                                    <li key={index} className="list-item">
                                        <div><img src={item.img} alt="" /></div>
                                        <div className="name"><span>{item.name} </span> #<span>{item.id}</span></div>
                                        <div className="other">
                                            <div className="birth">Minted on {item.birthday}</div>
                                            <div className="download">
                                                <img src={download} alt="" />
                                                <span>Download</span>
                                            </div>
                                        </div>
                                        <div className="line" />
                                    </li>
                                )
                            }
                        </ul>
                    </main>
                </section>
            </div>
        </div>

    )
}

export default Mine
