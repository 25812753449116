import { observable, action, computed } from 'mobx'
import * as anchor from '@project-serum/anchor'
import { awaitTransactionSignatureConfirmation, mintOneToken } from '../utils/ludens'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

class AppStore {
  @observable dialogOpen = false
  
  @observable mintStatus = 0
  
  @observable wallet = null
  
  @observable treasury = new anchor.web3.PublicKey(
    process.env.REACT_APP_TREASURY_ADDRESS
  )
  
  @observable config = new anchor.web3.PublicKey(
    process.env.REACT_APP_CANDY_MACHINE_CONFIG
  )
  
  @observable candyMachineId = new anchor.web3.PublicKey(
    process.env.REACT_APP_CANDY_MACHINE_ID
  )

// const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
  @observable network = process.env.REACT_APP_SOLANA_NETWORK
  
  @observable rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST
  
  @observable connection = new anchor.web3.Connection(this.rpcHost)
  
  @observable startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE, 10)
  
  @observable txTimeout = 30000 // milliseconds (confirm this works for your project)
  
  @observable candyMachine = null
  @observable refreshCandyMachineState = () => {}
  
  @action.bound setWallet (wallet) {
    this.wallet = wallet
  }
  @action.bound setRefresh (fun) {
    this.refreshCandyMachineState = fun
  }
  
  @action.bound openDialog () {
    this.dialogOpen = true
  }
  
  @action.bound closeDialog () {
    this.dialogOpen = false
    this.mintStatus = 0
  }
  
  @action.bound setMachine (machine) {
    this.candyMachine = machine
  }
  
  @action.bound
  async onMint () {
    try {
      // setIsMinting(true);
      if (this.wallet && this.candyMachine?.program) {
        const mintTxId = await mintOneToken(
          this.candyMachine,
          this.config,
          this.wallet.publicKey,
          this.treasury
        )
        
        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          this.txTimeout,
          this.connection,
          'singleGossip',
          false
        )
        console.log(status, 'status')
        if (!status?.err) {
          // setAlertState({
          //   open: true,
          //   message: "Congratulations! Mint succeeded!",
          //   severity: "success",
          // });
          this.mintStatus = 1
        } else {
          // setAlertState({
          //   open: true,
          //   message: "Mint failed! Please try again!",
          //   severity: "error",
          // });
          this.mintStatus = -1
        }
      }
    } catch (error) {
      // TODO: blech:
      let message = error.msg || 'Minting failed! Please try again!'
      if (!error.msg) {
        if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`
          // setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`
        }
      }
      //
      // setAlertState({
      //   open: true,
      //   message,
      //   severity: "error",
      // });
    } finally {
      if (this.wallet) {
        const balance = await this.connection.getBalance(this.wallet.publicKey)
        // setBalance(balance / LAMPORTS_PER_SOL);
      }
      // setIsMinting(false);
      this.refreshCandyMachineState && this.refreshCandyMachineState();
    }
  };
}

export default new AppStore()
