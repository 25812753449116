import { useState } from 'react'
import { Header, Footer } from '../../components'
import { Button, LinearProgress } from '@material-ui/core'
import { Banner } from '../../components'
import './index.scss'
import Leonard from '../../assets/monsters/Leonard.png'
import Nana from '../../assets/monsters/Nana.png'
import Crocco from '../../assets/monsters/Crocco.png'
import Sneeves from '../../assets/monsters/Sneeves.png'
import Princess from '../../assets/monsters/Princess.png'
import Warren from '../../assets/monsters/Warren.png'
import home1 from '../../assets/home/1.png'
import home2 from '../../assets/home/2.png'
import home3 from '../../assets/home/3.png'
import home4 from '../../assets/home/4.png'
import home5 from '../../assets/home/5.png'
import home6 from '../../assets/home/6.png'
import part3 from '../../assets/home/part-3.png'
import warfare from '../../assets/home/warfare.png'
import { useStores } from '../../store'
import { observer } from 'mobx-react'
import plattowin from '../../assets/video/playtowin.mp4'

const Monsters = [
  {
    name: 'Leonard',
    intro: 'Leonard is the poster child for lameness. His hair is unkempt and grows wildly like a mushroom on his head. His skin is pale and easily burnt, so much so it remains an orange hue. He wears lame striped polo shirts and holds close a pendant around his neck (a merch item referencing his favourite book series).',
    attr: 'Interminable gloominess.',
    img: Leonard
  },
  {
    name: 'NANA',
    intro: 'Before the war, Nana was bitten by a vampire and has two distinct bite marks towards the nape of her neck. She is interminably 16 years old, wears only her schoolgirl uniform, and has two glowing, radiating green eyes.',
    attr: 'Shapeshifting, superhuman speed',
    img: Nana
  },
  {
    name: 'Crocco Rocco',
    intro: 'Wears a croc mask, which rests permanently affixed to his head. Radioactive crocodiles think he is one of them.',
    attr: 'Camouflage, deceit.',
    img: Crocco
  },
  {
    name: 'Sneeves',
    intro: 'Sneeves wears his priest garb to remain his closeness to the lord in this unpredictable world. A chain of rosary beads lay around his neck and he constantly smooths his fingers over the beads to not only pray to God but to satiate his perpetual nervousness.',
    attr: 'Spiritual intuition',
    img: Sneeves
  },
  {
    name: 'Princess Stella',
    intro: 'Stella is a large worm, around the size of a commonplace domesticated animal. Her colouring is attributed to exposure to radiation and is a glowing, sickly green. Her three large eyes allow her a heightened sense of vision.',
    attr: 'Multi-vision.',
    img: Princess
  },
  {
    name: 'Mystery Prize',
    intro: '???This man is a mystery???',
    attr: 'Brute strength',
    img: Warren
  }
]
export default observer(({
  leftAmount = 100,
  total = 200
}) => {
  const { appStore = {} } = useStores()
  const { openDialog, onMint, wallet } = appStore
  return (
    <div className="home">
      <div className="welcome">
        <div>
          <h1 className="title">WELCOME TO <br/>LUDENS NO.3</h1>
          <div className="mint">
            <span className="numbers">{leftAmount}<span> / {total}</span> left</span>
            {/*<el-progress*/}
            {/*    style="margin-top: 25px;"*/}
            {/*:stroke-width="30"*/}
            {/*:percentage="leftAmount/total * 100"*/}
            {/*:show-text="false"*/}
            {/*/>*/}
            <LinearProgress value={leftAmount / total * 100} variant={'determinate'}/>
            {
              wallet && <Button onClick={openDialog} className="mint-button">Mint</Button>
            }
          
          </div>
        </div>
      </div>
      <Banner title="The year is 2042"/>
      <div className="part part-1">
        <div className="left">
          <h2 className="part-title">Ongoing nuclear warfare</h2>
          <p className="part-content">Ludens No.3 takes place in the year 2042, a world devastated by ongoing nuclear
            warfare. Extreme radiation has deteriorated crucial human functions, and to survive, humanity has fused with
            technology.</p>
        </div>
        <div className="right">
          <img src={warfare} alt=""/>
        </div>
      </div>
      <div className="part part-2">
        <div className="left">
          <div className="left-item-container">
            <div className="left-item"><img src={home1} alt=""/></div>
            <div className="left-item"><img src={home2} alt=""/></div>
            <div className="left-item"><img src={home3} alt=""/></div>
          </div>
          <div className="left-item-container">
            <div className="left-item"><img src={home4} alt=""/></div>
            <div className="left-item"><img src={home5} alt=""/></div>
            <div className="left-item"><img src={home6} alt=""/></div>
          </div>
        </div>
        <div className="right">
          <h2 className="part-title">Ludens No.3 is an open-world role-playing game</h2>
          <p className="part-content">
            Players will choose a character with a randomly assigned role/class before entering the world of Ludens, and
            their unique abilities and attributes will aid you in traversing this unpredictable landscape, making the
            gameplay experience unique to every player.
          </p>
        </div>
      </div>
      <div className="part part-1">
        <div className="left">
          <h2 className="part-title">Play to win</h2>
          <p className="part-content">Play to win in-game Gelt (tokens) to exchange for essential equipment, provisions,
            and weapons to assist your character’s journey in preparation for the looming nuclear apocalypse. Better
            yet, swap tokens in exchange for uncontaminated canned vegetables for your character to enjoy before the
            nuclear war! Be prepared, because the third wave is coming!</p>
        </div>
        <div className="right">
          <video
            preload="metadata"
            autoPlay
            loop
            src={plattowin}/>
        </div>
      </div>
      <Banner title="Meet your little monsters!" style={{ backgroundColor: 'white' }}/>
      <div className="monsters">
        <ul>
          {
            Monsters.map((item, index) =>
              <li className="monsters-item" key={index}>
                <div className="avatar">
                  <img src={item.img} alt=""/>
                </div>
                <p className="name">{item.name}</p>
                <p className="attr">
                  <span>Power/attributes </span>
                  <span>{item.attr}</span>
                </p>
                <p className={`${index !== 5 ? 'intro intro-indent' : 'intro'}`}>{item.intro}</p>
              </li>
            )
          }
        
        </ul>
      </div>
    </div>
  )
})
