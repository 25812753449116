import { useEffect, useState } from 'react'
import './index.scss'
import { observer } from 'mobx-react'
import logo from '../../assets/logo.png'
import { NavLink, Link } from 'react-router-dom'
import { Button, CircularProgress, Snackbar } from '@material-ui/core'
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui'

import { useStores } from '../../store'

export default observer(() => {
  const { appStore = {} } = useStores()
  const { openDialog, wallet } = appStore
  
  return (
    <header id="nav" className="header">
      <ul className="nav-header nav-left">
        <li>
          <a>Roadmap</a>
        </li>
        <li>
          <a>Team</a>
        </li>
        <li>
          <a>Support</a>
        </li>
      </ul>
      <Link to={'/'}>
        <img alt="ludens" src={logo} style={{ maxHeight: '100%' }}/>
      </Link>
      
      <ul className="nav-header nav-right">
        <li>
          <NavLink activeClassName={'activeLink'} to="/mine">My Ludens NIKI</NavLink>
        </li>
        <li>
          {
            wallet ? <Button className="mint-button" onClick={openDialog}>Mint</Button>
              : <WalletDialogButton>Connect wallet</WalletDialogButton>
          }
        
        </li>
      </ul>
    </header>
  )
})
