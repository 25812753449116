import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { TextField, Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Header, Footer } from '../components'
import Dialog from '@material-ui/core/Dialog'
import './dialog.scss'
import logo from '../assets/logo.png'
import Home from '../views/Home'
import Mine from '../views/Mine'
import { useStores } from '../store'
import { getCandyMachineState } from '../utils/ludens'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { useAnchorWallet } from '@solana/wallet-adapter-react'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon/>
      </IconButton>
    </MuiDialogTitle>
  )
})

export default observer(() => {
  const [val, setVal] = useState(0)
  const { appStore = {} } = useStores()
  const wallet = useAnchorWallet()
  const {
    dialogOpen,
    closeDialog,
    alertState: date,
    candyMachineId,
    connection,
    setWallet,
    onMint,
    setMachine,
    mintStatus,
    setRefresh
  } = appStore
  const [balance, setBalance] = useState(0)
  const [isActive, setIsActive] = useState(false) // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false) // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false) // true when user got to press MINT
  
  const [itemsAvailable, setItemsAvailable] = useState(0)
  const [itemsRedeemed, setItemsRedeemed] = useState(0)
  const [itemsRemaining, setItemsRemaining] = useState(0)
  
  const [alertState, setAlertState] = useState({
    open: false,
    message: '',
    severity: undefined,
  })
  
  const [startDate, setStartDate] = useState(new Date(date))
  
  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return
      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet,
        candyMachineId,
        connection
      )
      setItemsAvailable(itemsAvailable)
      setItemsRemaining(itemsRemaining)
      setItemsRedeemed(itemsRedeemed)
      setIsSoldOut(itemsRemaining === 0)
      setStartDate(goLiveDate)
      setMachine(candyMachine)
    })()
  }
  
  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey)
        setBalance(balance / LAMPORTS_PER_SOL)
      }
    })()
  }, [wallet, connection])
  
  useEffect(() => {
    setRefresh(refreshCandyMachineState)
  }, [])
  
  useEffect(() => {
    console.log(wallet, 'wallet')
    setWallet(wallet)
  }, [wallet])
  
  useEffect(refreshCandyMachineState, [
    wallet,
    candyMachineId,
    connection,
  ])
  
  return (
    <Router>
      <Header/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/mine" component={Mine}/>
      </Switch>
      <Footer/>
      <Dialog open={dialogOpen} onClose={closeDialog} className="mint-dialog">
        <div className="dialog-content">
          <DialogTitle onClose={closeDialog}/>
          <img className="logo" src={logo} alt=""/>
          {
            mintStatus === 1 ?
              <div style={{ width: '100%' }}>
                <h1 className="title">Congrats! You have one Ludens No.3 role now!</h1>
                <p className="price">See them at your wallet.</p>
              </div> :
              <div style={{ width: '100%' }}>
                <h1 className="title">Claim your role</h1>
                <p className="price">Mint price 1.5 SOL per NFT</p>
                {
                  mintStatus === -1 && <p className="errorTip">Sorry, you don’t have enough SOL.</p>
                }
                {/*<div>*/}
                {/*    <div className="nums">*/}
                {/*        <span>How many NIKIs?</span>*/}
                {/*        <span>Max(10)</span>*/}
                {/*    </div>*/}
                {/*    <TextField*/}
                {/*        value={val}*/}
                {/*        id="outlined-number"*/}
                {/*        type="number"*/}
                {/*        InputLabelProps={{*/}
                {/*            shrink: true,*/}
                {/*        }}*/}
                {/*        onChange={(e) => {*/}
                {/*            const v = e.target.value*/}
                {/*            if(v < 0){*/}
                {/*                setVal(0)*/}
                {/*            }else {*/}
                {/*                setVal(v)*/}
                {/*            }*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <div className="total">Total:[100]SOL</div>*/}
                {/*</div>*/}
                
                <div className="btn-group-custom">
                  <Button className="btn-confirm" onClick={onMint}>Confirm</Button>
                  <Button className="btn-cancel" onClick={closeDialog}>Cancel</Button>
                </div>
              </div>
          }
        </div>
      </Dialog>
    </Router>
  )
})
